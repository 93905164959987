











































import mixins from 'vue-typed-mixins'
import Newsletter from '../mixins/Newsletter'

export default mixins(Newsletter).extend({
  name: 'Newsletter7',
  data () {
    return {
    }
  }
})
